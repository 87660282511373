import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'

const BlogIndex = ({
	data: {
		allMarkdownRemark: { edges },
	},
}) => {
	const Posts = edges
		.filter(edge => edge.node.fields.isPost)
		.filter(edge => !edge.node.frontmatter.draft)
		.map(edge => (
			<div key={edge.node.id} className="post-listing">
				<Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
				<small>{edge.node.fields.date}</small>
				<span>{edge.node.excerpt}</span>
			</div>
		))

	return (
		<Layout title="Blog Posts">
			<div className="article">
				<h1>Blog Posts</h1>
				<div>{Posts}</div>
			</div>
		</Layout>
	)
}

export default BlogIndex

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [fields___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						title
						draft
					}
					fields {
						date(formatString: "MMMM DD, YYYY")
						slug
						isPost
					}
				}
			}
		}
	}
`
